import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Murtaza Malek — Web Engineer, Evid Learner and Reader, Tech Evangelist.',
			metaTags: [
				{
					name: 'description',
					content: 'I am Murtaza based in Indore, India! I am currently focusing on building websites and web apps. I’ve been building stuff on the web for the last 11 years, working with clients across various countries and industries.'
				},
				{
					property: 'og:description',
					content: 'I am Murtaza based in Indore, India! I am currently focusing on building websites and web apps. I’ve been building stuff on the web for the last 11 years, working with clients across various countries and industries.'
				}
			]
		}
	},
	{
		path: '/about-me',
		name: 'About',
		component: () => import(/* webpackChunkName: "about-me" */ '../views/AboutMe.vue')
	},
	{
		path: '/what-i-do',
		name: 'What I Do',
		component: () => import(/* webpackChunkName: "what-i-do" */ '../views/WhatIDo.vue')
	},
	{
		path: '/connect-with-me',
		name: 'Connect With Me',
		component: () => import(/* webpackChunkName: "connect-with-me" */ '../views/ConnectWithMe.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
